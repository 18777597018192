'use client';

import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { fetchAuthSession, fetchUserAttributes, signOut } from 'aws-amplify/auth';
import { AuthContext } from '../utils/client-provider';
import { PiAddressBook, PiSignOut, PiArrowRightFill } from 'react-icons/pi';
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import useUserStore from '@/store/userDataStore';
import Image from 'next/image';
import { get } from 'aws-amplify/api';

export default function Header() {
  const { user, setUser } = useContext(AuthContext);
  const userData = useUserStore((state: any) => state.userData);
  const userDataLoaded = useUserStore((state: any) => state.isLoaded);
  const setUserData = useUserStore((state: any) => state.setUserData);
  const setFollows = useUserStore((state: any) => state.setFollows);
  const setGames = useUserStore((state: any) => state.setGames);
  const setMyGames = useUserStore((state: any) => state.setMyGames);
  const [signingOut, setSigningOut] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const loadMyGames = async () => {
      try {
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (!authToken) throw new Error('Kein Auth-Token gefunden');
        const restOperation = get({
          apiName: 'api',
          path: '/user/mygames',
          options: {
            headers: {
              Authorization: authToken,
            },
          },
        });
        const response = await restOperation.response;
        const body: any = await response.body.json();
        //convert the object to an array
        setMyGames(body);
      } catch (e) {}
    };
    const loadFollows = async () => {
      try {
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (!authToken) throw new Error('Kein Auth-Token gefunden');
        const restOperation = get({
          apiName: 'api',
          path: '/follows',
          options: {
            headers: {
              Authorization: authToken,
            },
          },
        });
        const response = await restOperation.response;
        const body: any = await response.body.json();
        let follows = [];
        for (const follow of body) {
          follows.push(follow.SK.split('#')[1]);
        }
        setFollows(follows);
      } catch (e) {
        console.log(e);
      }
    };

    if (user && !userDataLoaded) {
      fetchUserAttributes().then((user) => {
        setUserData(user);
      });
      loadFollows();
      loadMyGames();
    }
  }, [user, userDataLoaded, setUserData, setFollows, setMyGames]);

  useEffect(() => {
    const loadGames = async () => {
      try {
        const restOperation = get({
          apiName: 'api',
          path: '/games',
        });
        const response = await restOperation.response;
        const body: any = await response.body.json();
        setGames(body);
      } catch (e) {
        console.log(e);
      }
    };
    loadGames();
  }, [setGames]);

  const handleSignOut = async () => {
    setSigningOut(true);
    await signOut();
    setUser(null);
    setSigningOut(false);
  };

  return (
    <>
      <nav className="bg-neutral-200 w-full text-neutral-600  border-b border-neutral-400">
        <div className="container md:px-20 mx-auto px-4 h-20 flex items-center justify-between">
          <Link href="/" className="flex items-center h-15">
            <div className="h-12 flex items-center">
              <Image src="/files/Logo V1.svg" alt="Logo" width={190} height={48} />
            </div>
          </Link>
          <div className="flex items-center md:hidden ml-auto">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="focus:outline-none">
              <IoIosMenu fontSize={30} />
            </button>
          </div>
          <div className="hidden md:flex items-center justify-center w-full text-xl pt-2">
            <Link href="/" className="text-xl mx-2">
              Home
            </Link>
            <Link href="/events" className="text-xl mx-2">
              Turniere
            </Link>
           
            {user && (
              <>
                <Link href="/user/me" className="text-xl mx-2">
                  Account
                </Link>
                <button
                  onClick={handleSignOut}
                  className="text-xl mx-2 text-neutral-600 transition-colors flex items-center"
                >
                  {signingOut ? (
                    <div className="flex items-center">
                      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                      Abmelden
                    </div>
                  ) : (
                    <>Abmelden</>
                  )}
                </button>
              </>
            )}
            {!user && (
              <>
                <Link href="/user/login" className="text-xl mx-2">
                  Anmelden
                </Link>
                <Link href="/user/new" className="text-xl mx-2">
                  <button className="bg-primary flex items-center justify-center rounded-full px-4 py-2 text-xl text-white transition duration-300 ease-in-out hover:bg-blue-900">
                    Registrieren
                  </button>
                </Link>
              </>
            )}
             {userData && user && userData['custom:host'] === 'true' && (
              <Link href="/host/events" className="text-xl text-blue-800 mx-2">
                Veranstalter
              </Link>
            )}
          </div>
        </div>
        {isMenuOpen && (
          <div
            className={`fixed top-0 right-0 w-4/5 h-full bg-white text-neutral-800 shadow-lg z-50 transform transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
          >
            <div className="bg-primary p-4 text-white text-2xl flex justify-end">
              <button onClick={() => setIsMenuOpen(false)} className="focus:outline-none">
                <IoIosClose className="text-2xl" />
              </button>
            </div>
            <Link
              href="/"
              onClick={() => setIsMenuOpen(false)}
              className="flex justify-between items-center p-4 hover:bg-gray-200 transition-colors"
            >
              Home <PiArrowRightFill className="text-xl" />
            </Link>
            <Divider />
            <Link
              href="/events"
              onClick={() => setIsMenuOpen(false)}
              className="flex justify-between items-center p-4 hover:bg-gray-200 transition-colors"
            >
              Turniere <PiArrowRightFill className="text-xl" />
            </Link>
            <Divider />
            {!user ? (
              <>
                <Link
                  href="/user/login"
                  onClick={() => setIsMenuOpen(false)}
                  className="flex justify-between items-center p-4 hover:bg-gray-200 transition-colors"
                >
                  Anmelden <PiArrowRightFill className="text-xl" />
                </Link>
                <Divider />
              </>
            ) : (
              <>
                <Link
                  href="/user/me"
                  onClick={() => setIsMenuOpen(false)}
                  className="flex justify-between items-center p-4 hover:bg-gray-200 transition-colors"
                >
                  Account <PiArrowRightFill className="text-xl" />
                </Link>
                <Divider />
                <button
                  onClick={handleSignOut}
                  className="w-full text-left hover:bg-gray-200 transition-colors flex justify-between items-center p-4"
                >
                  {signingOut ? (
                    <div className="flex items-center">
                      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                      Abmelden
                    </div>
                  ) : (
                    <>
                      Abmelden <PiArrowRightFill className="text-xl" />
                    </>
                  )}
                </button>
                <Divider />
                {userData && userData['custom:host'] === 'true' && (
                  <>
                    <Link
                      href="/host/scanner"
                      onClick={() => setIsMenuOpen(false)}
                      className="flex justify-between items-center p-4 hover:bg-gray-200 transition-colors"
                    >
                      Ticket-Scanner
                      <PiArrowRightFill className="text-xl" />
                    </Link>
                    <Divider />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </nav>
    </>
  );
}

const Divider = () => <div className="h-px bg-gray-200" />;

// fetcher.ts
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

type FetcherMethod = "GET" | "POST" | "PUT" | "DELETE";

interface FetcherOptions {
  method: FetcherMethod;
  path: string;
  withAuth?: boolean;
  queryParams?: Object;
}

const fetcher = async ({
  method,
  path,
  withAuth = false,
  queryParams,
}: FetcherOptions): Promise<any> => {
  try {
    const apiOptions: any = {
      apiName: "api",
      path: path,
    };

    if (withAuth) {
      const session = await fetchAuthSession();
      const authToken = session.tokens?.idToken?.toString();
      if (!authToken) throw new Error("Kein Auth-Token gefunden");
      apiOptions.options = {
        headers: {
          Authorization: authToken,
        },
        queryParams: { ...queryParams },
      };
    }

    let response;
    switch (method) {
      case "GET":
        response = await get(apiOptions);
        break;
      // Hier kannst du weitere Methoden (POST, PUT, DELETE) hinzufügen
      default:
        throw new Error(`Methode ${method} wird nicht unterstützt.`);
    }

    const result = await (await response.response).body.json();
    return result;
  } catch (error) {
    console.error(`Fetcher Fehler bei ${method} ${path}:`, error);
    throw error;
  }
};

export default fetcher;

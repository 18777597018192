'use client';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}

body {
    font-family: 'dmsans', sans-serif;
  }


a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
`;
